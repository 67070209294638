import React, { Component } from 'react'
import './style/Product.css'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
import { Carousel } from 'react-responsive-carousel'
import {
  Layout,
  Switch,
  PageHeader,
  Input,
  Button,
  Tag,
  Typography,
  Modal,
  Form,
  Row,
  Col,
  Skeleton,
  message,
  Radio,
  Divider,
  AutoComplete,
} from 'antd'

import axios from 'axios'
import { Tabs } from 'antd'
import CKEditor from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

const { Content } = Layout
const { Paragraph } = Typography
const options = [
  { label: 'Gold-Bar', value: 'Gold' },
  { label: 'Silver-Bar', value: 'Silver' },
  { label: 'Diamond', value: 'Diamond' },
  { label: 'Mints', value: 'Mints' },
  { label: 'Other', value: 'others' },
]
const weight = [
  { label: '1kg', value: '1000' },
  { label: '500gm', value: '500' },
  { label: '250gm', value: '250' },
  { label: '100gm', value: '100' },
  { label: '50gm', value: '50' },
  { label: '20gm', value: '20' },
  { label: '10gm', value: '10' },
  { label: '5gm', value: '5' },
  { label: '1gm', value: '1' },
  { label: '1oz', value: '28.3495' },
  { label: '0.5oz', value: '14.1748' },
  { label: '0.25oz', value: '7.087381' },
  { label: '0.1oz', value: '2.83495' },
  { label: '1oz t', value: '31.1035' },
  { label: '0.5oz t', value: '15.5517' },
  { label: '0.25oz t', value: '7.775869' },
  { label: '0.1oz t', value: '3.11035' },
]
const categoryToPurity = (category) => {
  if (category === 'Diamond') {
    return 'Carat'
  }
  return 'Purity'
}
// Dropdown
const onClick = ({ key }) => {
  if (key == 1) {
    console.log('Edit Button Clicked')
  } else {
    console.log('Delete button is clicked')
  }
}

// Tabs
const { TabPane } = Tabs

const IconLink = ({ src, text, onClick }) => (
  <a href="#" className="example-link" onClick={onClick}>
    <img className="example-link-icon" src={src} alt={text} />
    {text}
  </a>
)

const onFinish = (values) => {
  console.log('Success:', values)
}

const onFinishFailed = (errorInfo) => {
  console.log('Failed:', errorInfo)
}
const documentCategory = [
  { label: 'Gold', value: 'Gold' },
  { label: 'Silver', value: 'Silver' },
  { label: 'Diamond', value: 'Diamond' },
  { label: 'Other', value: 'Other' },
]

class Product extends Component {
  state = {
    visible_upload: false,
    visible_product: false,
    visible_gallery: false,
    visible_product_doc: false,
    visible_product_doc_details: false,
    document: [],
    title: '',
    subtitle: '',
    category: '',
    detail: '',
    weight: 0,
    price: null,
    quantity: 0,
    purity: '',
    imagelink1: '',
    imagelink2: '',
    imagelink3: '',
    imagelink4: '',
    imagelink5: '',
    dimension: '',
    products: [],
    loading: true,
    value: 'Gold',
    other: '',
    latestProduct: [],
    goldmakingchargedata: {},
    goldmakingcharge: {},
    silvermakingchargedata: {},
    silvermakingcharge: {},
    goldPricecurrent: '',
    silverPricecurrent: '',
    productImageGallery: [],
    productDocImageGallery: [],
    img_count: 0,
    prev_img_link: '',
    productcategory: '',
    goldDocument: [],
    silverDocument: [],
    diamondDocument: [],
    otherDocument: [],
    newPrice: null,
    webShopClose: false,
  }
  productLivePrice = (product) => {
    if (product.category === 'Gold') {
      switch (product.weight) {
        case 28.3495:
          return Math.round(this.state.goldPricecurrent * 28.3495)
        case 14.1748:
          return Math.round(this.state.goldPricecurrent * 14.1748)
        case 2.83495:
          return Math.round(this.state.goldPricecurrent * 2.83495)
        case 1:
          return (
            this.state.goldPricecurrent * 1 + this.state.goldmakingcharge.one
          )
        case 5:
          return (
            this.state.goldPricecurrent * 5 + this.state.goldmakingcharge.five
          )
        case 10:
          return (
            this.state.goldPricecurrent * 10 + this.state.goldmakingcharge.ten
          )
        case 20:
          return (
            this.state.goldPricecurrent * 20 + this.state.goldmakingcharge.fifty
          )
        case 50:
          return (
            this.state.goldPricecurrent * 50 + this.state.goldmakingcharge.fifty
          )
        case 100:
          return (
            this.state.goldPricecurrent * 100 +
            this.state.goldmakingcharge.onehundred
          )
        case 250:
          return (
            this.state.goldPricecurrent * 250 +
            this.state.goldmakingcharge.twohundredfifty
          )
        case 500:
          return (
            this.state.goldPricecurrent * 500 +
            this.state.goldmakingcharge.fivehundred
          )
        case 1000:
          return (
            this.state.goldPricecurrent * 1000 +
            this.state.goldmakingcharge.onethousand
          )
        default:
          return null
      }
    } else if (product.category === 'Silver') {
      switch (product.weight) {
        case 1:
          return (
            this.state.silverPricecurrent * 1 +
            this.state.silvermakingcharge.one
          )
        case 5:
          return (
            this.state.silverPricecurrent * 5 +
            this.state.silvermakingcharge.five
          )
        case 10:
          return (
            this.state.silverPricecurrent * 10 +
            this.state.silvermakingcharge.ten
          )
        case 20:
          return (
            this.state.silverPricecurrent * 20 +
            this.state.silvermakingcharge.twenty
          )
        case 50:
          return (
            this.state.silverPricecurrent * 50 +
            this.state.silvermakingcharge.fifty
          )
        case 100:
          return (
            this.state.silverPricecurrent * 100 +
            this.state.silvermakingcharge.onehundred
          )
        case 250:
          return (
            this.state.silverPricecurrent * 250 +
            this.state.silvermakingcharge.twohundredfifty
          )
        case 500:
          return (
            this.state.silverPricecurrent * 500 +
            this.state.silvermakingcharge.fivehundred
          )
        case 1000:
          return (
            this.state.silverPricecurrent * 1000 +
            this.state.silvermakingcharge.onethousand
          )
        default:
          return null
      }
    } else if (product.category === 'Diamond') {
      return product.price
    } else {
      return product.price
    }
  }

  showModal1 = () => {
    this.setState({
      visible_upload: true,
    })
  }
  showModal_gallery = () => {
    this.setState({
      visible_gallery: true,
    })
  }
  showMoadal_product_doc_details = () => {
    this.setState({
      visible_product_doc_details: true,
    })
  }
  showMoadal_product_doc = () => {
    this.setState({
      visible_product_doc: true,
    })
  }

  deleteDocImage = (imagelink) => {
    console.log(imagelink)
    this.setState({ visible_gallery: false })
    axios
      .delete(
        `${process.env.REACT_APP_API_URL}/api/product-document/${imagelink}`
      )
      .then((res) => {
        let data = res.data
        console.log(this.data)
        if (this.state.data) {
          message.success('Product Image Deleted Successfully', 5)
        }
        window.location.reload(false)
      })
      .catch(function (error) {
        console.log(error)
        message.error('Error In Deleting Product Image')
      })
  }

  deleteImage = (imagelink) => {
    console.log(imagelink)
    this.setState({ visible_gallery: false })
    axios
      .delete(`${process.env.REACT_APP_API_URL}/api/product-image/${imagelink}`)
      .then((res) => {
        let data = res.data
        console.log(this.data)
        if (this.state.data) {
          message.success('Product Image Deleted Successfully', 5)
        }
        window.location.reload(false)
      })
      .catch(function (error) {
        console.log(error)
        message.error('Error In Deleting Product Image')
      })
  }
  passDocImageUrl = (imagelink) => {
    console.log('selected', imagelink)
    if (this.state.imagelink1 === '') {
      this.setState({ visible_gallery: false, imagelink1: imagelink })
    } else if (this.state.imagelink2 === '') {
      this.setState({ visible_gallery: false, imagelink2: imagelink })
    } else if (this.state.imagelink3 === '') {
      this.setState({ visible_gallery: false, imagelink3: imagelink })
    } else if (this.state.imagelink4 === '') {
      this.setState({ visible_gallery: false, imagelink4: imagelink })
    } else if (this.state.imagelink5 === '') {
      this.setState({ visible_gallery: false, imagelink5: imagelink })
    } else {
      message.error('Maximum Document Limit Reached')
      this.setState({ visible_gallery: false })
    }
  }

  passImageUrl = (imagelink) => {
    console.log('selected', imagelink)
    if (this.state.imagelink1 === '') {
      this.setState({ visible_gallery: false, imagelink1: imagelink })
    } else if (this.state.imagelink2 === '') {
      this.setState({ visible_gallery: false, imagelink2: imagelink })
    } else if (this.state.imagelink3 === '') {
      this.setState({ visible_gallery: false, imagelink3: imagelink })
    } else if (this.state.imagelink4 === '') {
      this.setState({ visible_gallery: false, imagelink4: imagelink })
    } else if (this.state.imagelink5 === '') {
      this.setState({ visible_gallery: false, imagelink5: imagelink })
    } else {
      message.error('Maximum Image Limit Reached')
      this.setState({ visible_gallery: false })
    }
  }
  handleDisableProduct = (data) => {
    console.log(data)
  }
  metalCategoryTag = (category) => {
    if (category == 'Gold') {
      return (
        <Tag className="gold-tag" color="#eade9b">
          Gold
        </Tag>
      )
    } else if (category == 'Silver') {
      return <Tag className="silver-tag">Silver</Tag>
    } else if (category == 'Diamond') {
      return <Tag className="silver-tag">Diamond</Tag>
    } else {
      return <Tag className="silver-tag">Other</Tag>
    }
  }
  uploadProductDoc = (e) => {
    message.warning('Uploading Document')
    // e.prevent.default()
    console.log(this.state)

    const docURL1 = this.state.imagelink1
    const docURL2 = this.state.imagelink2
    const docURL3 = this.state.imagelink3
    const docURL4 = this.state.imagelink4
    const docURL5 = this.state.imagelink5
    const category = this.state.productcategory

    axios
      .post(`${process.env.REACT_APP_API_URL}/api/document/register`, {
        docURL1: docURL1,
        docURL2: docURL2,
        docURL3: docURL3,
        docURL4: docURL4,
        docURL5: docURL5,
        category: category,
      })
      .then((res) => {
        let data = res.data
        console.log(this.data)
        if (this.state.data) {
          message.success('Product Image Added Successfully', 5)
        }
        this.setState({
          imageURL1: '',
          imageURL2: '',
          imageURL3: '',
          imageURL4: '',
          imageURL5: '',
          productcategory: '',
        })
        window.location.reload(false)
      })
      .catch(function (error) {
        console.log(error)
        message.error('Error In Uploading Product Document')
      })
  }
  uploadProductDocImg = (e) => {
    message.warning('Uploading Image')
    // e.prevent.default()
    console.log(this.state)
    const formData = new FormData()
    formData.append('file', this.state.files)
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/product-document/register`,
        formData
      )
      .then((res) => {
        let data = res.data
        console.log(this.data)
        if (this.state.data) {
          message.success('Product Image Added Successfully', 5)
        }
        window.location.reload(false)
      })
      .catch(function (error) {
        console.log(error)
        message.error('Error In Uploading Product Image')
      })
  }
  deleteComment = (id) => {
    axios
      .post(`https://radiant-depths-81820.herokuapp.com/api/product/comment/`, {
        id: id,
      })
      .then((res) => {
        console.log(res)
        window.location.reload()
      })
      .catch((err) => {
        console.log(err)
      })
  }
  uploadProductImage = (e) => {
    message.warning('Uploading Image')
    // e.prevent.default()
    console.log(this.state)
    const formData = new FormData()
    formData.append('file', this.state.files)
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/product-image/register`,
        formData
      )
      .then((res) => {
        let data = res.data
        console.log(this.data)
        if (this.state.data) {
          message.success('Product Image Added Successfully', 5)
        }
        window.location.reload(false)
      })
      .catch(function (error) {
        console.log(error)
        message.error('Error In Uploading Product Image')
      })
  }
  uploadFormDetails = (e) => {
    this.setState({
      visible_upload: false,
    })
    const {
      title,
      subtitle,
      category,
      weight,
      purity,
      price,
      detail,
      dimension,
      imagelink1,
      imagelink2,
      imagelink3,
      imagelink4,
      imagelink5,
    } = this.state
    axios
      .post(`${process.env.REACT_APP_API_URL}/api/product/register`, {
        title: title,
        subtitle: subtitle,
        category: category,
        weight: weight,
        carat: purity,
        rating: '0',
        price: price,
        detail: detail,
        dimension: dimension,
        imageURL1: imagelink1,
        imageURL2: imagelink2,
        imageURL3: imagelink3,
        imageURL4: imagelink4,
        imageURL5: imagelink5,
      })
      .then((res) => {
        const data = res.data
        this.setState({
          title: '',
          subtitle: '',
          category: '',
          detail: '',
          weight: 0,
          price: 0,
          purity: '',
          dimension: '',
          data,
          other: '',
          imagelink1: '',
          imagelink2: '',
          imagelink3: '',
          imagelink5: '',
        })
        if (this.state.data) {
          message.success('Product Added Successfully', 5)
        }
        window.location.reload(false)
      })
      .catch(function (error) {
        console.log(error)
        message.error('Error In Uploading Product')
      })
      .catch(function (error) {
        console.log(error)
        message.error('Error In Uploading Product')
      })
  }
  onChangeFile1(e) {
    let files1 = e.target.files
    this.setState({ files: files1[0] })
    console.log(files1[0])
    console.log(this.state.files)
  }
  handleCancel_product_doc = (e) => {
    console.log(e)
    this.setState({
      visible_product_doc: false,
    })
  }
  handleCancel_product_doc_details = (e) => {
    console.log(e)
    this.setState({
      visible_product_doc_details: false,
    })
  }
  handleCancel_gallery = (e) => {
    console.log(e)
    this.setState({
      visible_gallery: false,
    })
  }
  handleCancel1 = (e) => {
    console.log(e)
    this.setState({
      visible_upload: false,
    })
  }
  handleCanceldelete = (e) => {
    console.log(e)
    this.setState({
      visible_delete: false,
    })
  }
  handleOkupload = () => {
    console.log('upload product')
  }

  handleOkdelete = (modal_id) => {
    console.log(modal_id, 'delete this bitch ')
    axios
      .delete(`${process.env.REACT_APP_API_URL}/api/product/${modal_id}`)
      .then((res) => {
        const data = res.data
        console.log('Deleted Product Response', data)
        if (this.state.data) {
          message.success('Product Deleted Successfully', 5)
        }
        window.location.reload(false)
      })
      .catch(function (error) {
        console.log(error)
        message.error('Error In Deleting Product')
      })
  }

  handleWebShopStatus = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/web-shop/`)
      .then((res) => {
        this.setState((prevState) => ({
          webShopClose: !prevState.webShopClose,
        }))
      })
      .catch(function (error) {
        message.error('Error in switching webshop off')
        console.log(error)
      })
  }

  showModaldelete = (product) => {
    this.setState({
      visible_delete: true,
      modal_id: product._id,
      modal_title: product.title,
    })
  }
  handleProductPriceUpdate = (data) => {
    console.log('Product Details', data, this.state.newPrice)
    axios
      .post(`${process.env.REACT_APP_API_URL}/api/product/price-update`, {
        product: data,
        newPrice: this.state.newPrice,
      })
      .then((res) => {
        message.success('Price Updated')
      })
      .catch(function (error) {
        // handle error
        message.error('Error In Price Update')
        console.log(error)
      })
  }
  categoryTag = (product) => {
    if (product.category === 'Gold') {
      return 'Gold Purity in %'
    } else if (product.category === 'Silver') {
      return 'Silver Purity in %'
    } else if (product.category === 'Mints') {
      return 'Mint Purity in %'
    } else if (product.category === 'Diamond') {
      return 'Diamond Quality in kt'
    } else {
      return null
    }
  }
  handleProductStatus = (data, status) => {
    console.log('Product Disable', data, status)
    axios
      .post(`${process.env.REACT_APP_API_URL}/api/product/product-status`, {
        productStatus: status,
        product: data,
      })
      .then((res) => {
        message.success('Product Status Updated')
      })
      .catch(function (error) {
        // handle error
        message.error('Product Status  Error')
        console.log(error)
      })
  }
  // showModal2 = (product) => {
  //   this.setState({
  //     visible_product: true,
  //     modal_title: product.title,
  //     modal_subtitle: product.subtitle,
  //     modal_category: product.category,
  //     modal_detail: product.detail,
  //     modal_weight: product.weight,
  //     modal_price: product.price,
  //     modal_quantity: product.quantity,
  //     modal_purity: product.purity,
  //     modal_createdAt: product.createdAt,
  //     modal_updatedAt: product.updatedAt,
  //   });
  // };
  productWeightTag = (weight) => {
    if (weight === 28.3495) {
      return '1oz'
    } else if (weight === 14.1748) {
      return '0.5oz'
    } else if (weight === 7.087381) {
      return '0.25oz'
    } else if (weight === 2.83495) {
      return '0.1oz'
    } else if (weight === 31.1035) {
      return '1oz t'
    } else if (weight === 15.5517) {
      return '0.5oz t'
    } else if (weight === 7.775869) {
      return '0.25oz t'
    } else if (weight === 3.11035) {
      return '0.1oz t'
    } else {
      return `${weight}gm`
    }
  }
  productDocument = (category) => {
    if (category === 'Gold') {
      return (
        <Carousel className="product-img-view">
          <div>
            {this.state.goldDocument.docURL1 ? (
              <img src={this.state.goldDocument.docURL1} alt="gold-document" />
            ) : null}
          </div>
          <div>
            {this.state.goldDocument.docURL2 ? (
              <img src={this.state.goldDocument.docURL2} alt="gold-document" />
            ) : null}
          </div>
          <div>
            {this.state.goldDocument.docURL3 ? (
              <img src={this.state.goldDocument.docURL3} alt="gold-document" />
            ) : null}
          </div>
          <div>
            {this.state.goldDocument.docURL4 ? (
              <img src={this.state.goldDocument.docURL4} alt="gold-document" />
            ) : null}
          </div>
          <div>
            {this.state.goldDocument.docURL5 ? (
              <img src={this.state.goldDocument.docURL5} alt="gold-document" />
            ) : null}
          </div>
        </Carousel>
      )
    } else if (category === 'Silver') {
      return (
        <Carousel className="product-img-view">
          <div>
            {this.state.silverDocument.docURL1 ? (
              <img
                src={this.state.silverDocument.docURL1}
                alt="silver-document"
              />
            ) : null}
          </div>
          <div>
            {this.state.silverDocument.docURL2 ? (
              <img
                src={this.state.silverDocument.docURL2}
                alt="silver-document"
              />
            ) : null}
          </div>
          <div>
            {this.state.silverDocument.docURL3 ? (
              <img
                src={this.state.silverDocument.docURL3}
                alt="silver-document"
              />
            ) : null}
          </div>
          <div>
            {this.state.silverDocument.docURL4 ? (
              <img
                src={this.state.silverDocument.docURL4}
                alt="silver-document"
              />
            ) : null}
          </div>
          <div>
            {this.state.silverDocument.docURL5 ? (
              <img
                src={this.state.silverDocument.docURL5}
                alt="silver-document"
              />
            ) : null}
          </div>
        </Carousel>
      )
    } else if (category === 'Diamond') {
      return (
        <Carousel className="product-img-view">
          <div>
            {this.state.diamondDocument.docURL1 ? (
              <img
                src={this.state.diamondDocument.docURL1}
                alt="diamond-document"
              />
            ) : null}
          </div>
          <div>
            {this.state.diamondDocument.docURL2 ? (
              <img
                src={this.state.diamondDocument.docURL2}
                alt="diamond-document"
              />
            ) : null}
          </div>
          <div>
            {this.state.diamondDocument.docURL3 ? (
              <img
                src={this.state.diamondDocument.docURL3}
                alt="diamond-document"
              />
            ) : null}
          </div>
          <div>
            {this.state.diamondDocument.docURL4 ? (
              <img
                src={this.state.diamondDocument.docURL4}
                alt="diamond-document"
              />
            ) : null}
          </div>
          <div>
            {this.state.diamondDocument.docURL5 ? (
              <img
                src={this.state.diamondDocument.docURL5}
                alt="diamond-document"
              />
            ) : null}
          </div>
        </Carousel>
      )
    } else if (category === 'Other') {
      return (
        <Carousel className="product-img-view">
          <div>
            {this.state.otherDocument.docURL1 ? (
              <img
                src={this.state.otherDocument.docURL1}
                alt="other-document"
              />
            ) : null}
          </div>
          <div>
            {this.state.otherDocument.docURL2 ? (
              <img
                src={this.state.otherDocument.docURL2}
                alt="other-document"
              />
            ) : null}
          </div>
          <div>
            {this.state.otherDocument.docURL3 ? (
              <img
                src={this.state.otherDocument.docURL3}
                alt="other-document"
              />
            ) : null}
          </div>
          <div>
            {this.state.otherDocument.docURL4 ? (
              <img
                src={this.state.otherDocument.docURL4}
                alt="other-document"
              />
            ) : null}
          </div>
          <div>
            {this.state.otherDocument.docURL5 ? (
              <img
                src={this.state.otherDocument.docURL5}
                alt="other-document"
              />
            ) : null}
          </div>
        </Carousel>
      )
    }
  }

  handleCancel2 = (e) => {
    console.log(e)
    this.setState({
      visible_product: false,
    })
  }

  renderProduct = () => {
    return this.state.latestProduct.map((product) => {
      return (
        <PageHeader
          className="site-page-header"
          key={product._id}
          tags={''}
          extra={[
            <>{this.metalCategoryTag(product.category)}</>,
            <>
              <Tag color="blue">{this.productWeightTag(product.weight)}</Tag>
            </>,
            <>
              {/* {product.quantity > 1 ? ( */}
              <Tag
                style={{ borderColor: '#89b872', color: 'black' }}
                color="#d9f3cd"
              >
                In-Stock
              </Tag>
              {/* ) : ( */}
              {/* <Tag
                    style={{
                      borderColor: "#ff8e8e",
                      color: "black",
                      background: "#ffd7d7",
                    }}
                    color="##e70000"
                  >
                    Sold Out
                  </Tag> */}
              {/* )} */}
              {product.status ? (
                <>
                  <Button
                    onClick={() => this.handleProductStatus(product, false)}
                    type="danger"
                  >
                    Disable Product
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    type="primary"
                    onClick={() => this.handleProductStatus(product, true)}
                  >
                    Enable Product
                  </Button>
                </>
              )}
            </>,
          ]}
        >
          <Content>
            <Row justify="center">
              <Col span={8}>
                <Carousel className="product-img-view">
                  <div>
                    <img
                      src={
                        product.imageURL1
                          ? product.imageURL1
                          : require('./image_404.jpg')
                      }
                    />
                  </div>
                  <div>
                    <img
                      src={
                        product.imageURL2
                          ? product.imageURL2
                          : require('./image_404.jpg')
                      }
                    />
                  </div>
                  <div>
                    <img
                      src={
                        product.imageURL3
                          ? product.imageURL3
                          : require('./image_404.jpg')
                      }
                    />
                  </div>
                  <div>
                    <img
                      src={
                        product.imageURL4
                          ? product.imageURL4
                          : require('./image_404.jpg')
                      }
                    />
                  </div>
                  <div>
                    <img
                      src={
                        product.imageURL5
                          ? product.imageURL5
                          : require('./image_404.jpg')
                      }
                    />
                  </div>
                </Carousel>
              </Col>

              <Col span={16} style={{ paddingLeft: '1.5%' }}>
                <div style={{ margin: 20 }}>
                  <Tabs type="card">
                    <TabPane tab="About" key="1">
                      <div className="product-card-title">{product.title}</div>
                      <div className="product-card-subtitle">
                        {product.subtitle}
                      </div>
                      <div className="product-card-price">
                        <h2>{this.categoryTag(product)}</h2>
                        <h1>{product.carat}</h1>
                      </div>
                      <div className="product-card-price">
                        <h2>Dimensions</h2>
                        <h1>{product.dimension}</h1>
                        <h2>Price</h2>
                        <h1>{this.productLivePrice(product)}€</h1>
                      </div>
                      <div className="product-card-createdAt">
                        <h2>Created At : </h2>
                        <h1> {product.createdAt} </h1>
                      </div>
                      <div className="product-card-updatedAt">
                        <h2>Updated At : </h2>
                        <h1> {product.updatedAt} </h1>
                      </div>
                    </TabPane>
                    <TabPane tab="Details" key="2">
                      <div
                        dangerouslySetInnerHTML={{ __html: product.detail }}
                      />
                    </TabPane>
                    <TabPane tab="Product Document" key="3">
                      {this.productDocument(product.category)}
                    </TabPane>
                    <TabPane tab="Product Comment" key="4">
                      <ul
                        style={{
                          listStyleType: 'none',
                        }}
                      >
                        {product.comments
                          ? product.comments.map((comment) => (
                              <li>
                                <h3>{comment.name}</h3>
                                <p>{comment.comment}</p>
                                <Button
                                  style={{
                                    color: 'red',
                                  }}
                                  type="link"
                                  onClick={() =>
                                    this.deleteComment(comment._id)
                                  }
                                >
                                  Delete Comment
                                </Button>
                              </li>
                            ))
                          : null}
                      </ul>
                    </TabPane>
                  </Tabs>
                </div>
              </Col>
            </Row>

            <div style={{ marginTop: '1%' }}>
              <Row>
                <Col span={9}>
                  {/* 
                  <div className="avatar">E</div>
                  <div className="data-div">
                    <h1 className="home-panel-title">Esha Vats</h1>
                    <span className="home-panel-metadata">Uploaded by</span>
                  </div>
                   */}
                </Col>
                <Col span={7} style={{ paddingTop: '3%' }}></Col>
                <Col span={8} style={{ paddingTop: '2%' }}>
                  <div className="link-div">
                    {product.category == 'Silver' ||
                    product.category == 'Gold' ? null : (
                      <>
                        <Input
                          placeholder="New Price"
                          type="number"
                          onChange={(e) =>
                            this.setState({ newPrice: e.target.value })
                          }
                          style={{
                            width: '100px',
                          }}
                        />
                        <Button
                          type="primary"
                          onClick={() => this.handleProductPriceUpdate(product)}
                        >
                          Update Price
                        </Button>
                      </>
                    )}
                    <Button
                      size="large"
                      key="3"
                      style={{ marginLeft: 10 }}
                      onClick={() => this.showModaldelete(product)}
                      danger
                    >
                      Delete
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
          </Content>
        </PageHeader>
      )
    })
  }

  renderSkeleton() {
    return (
      <>
        <Skeleton loading={this.state.loading} active />
        <Skeleton loading={this.state.loading} active />
        <Skeleton loading={this.state.loading} active />
        <Skeleton loading={this.state.loading} active />
      </>
    )
  }

  componentDidMount() {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/product/all-product`)
      .then((res) => {
        const data = res.data
        const products = data.data
        const latestProduct = products.reverse()
        this.setState({ latestProduct, products, loading: false })
        console.log(this.state.latestProduct)
      })
      .catch(function (error) {
        // handle error
        console.log(error)
      })
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/product-document/all-image`)
      .then((res) => {
        const data = res.data.data
        const productDocImageGallery = data
        this.setState({ productDocImageGallery })
      })
      .catch(function (error) {
        // handle error
        console.log(error)
      })
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/product-image/all-image`)
      .then((res) => {
        const data = res.data.data
        const productImageGallery = data.reverse()
        this.setState({ productImageGallery })
      })
      .catch(function (error) {
        // handle error
        console.log(error)
      })
    // API for making charges for gold
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/making-charge-gold/all-making-gold`
      )
      .then((response) => {
        var goldmakingchargedata = response.data.data
        goldmakingchargedata.reverse()
        const goldmakingcharge = goldmakingchargedata[0]
        this.setState({ goldmakingchargedata, goldmakingcharge })
      })
      .catch(function (error) {
        // handle error
        console.log(error)
      })
    // API for making charges for silver
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/making-charge-silver/all-making-silver`
      )
      .then((response) => {
        var silvermakingchargedata = response.data.data
        silvermakingchargedata.reverse()
        const silvermakingcharge = silvermakingchargedata[0]
        this.setState({ silvermakingchargedata, silvermakingcharge })
        console.log('silver making charge', this.state.silvermakingcharge)
      })
      .catch(function (error) {
        // handle error
        console.log(error)
      })

    //API TO GET GOLD PRICE HISTORY
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/gold/all-gold`)
      .then((response) => {
        const goldPriceHistory = response.data.data.reverse()
        const goldPricecurrent = goldPriceHistory[0].goldprice
        this.setState({
          goldPricecurrent,
        })
        console.log('gold current price', goldPricecurrent)
      })
      .catch(function (error) {
        // handle error
        console.log(error)
      })
    // Silver currrent price API call
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/silver/all-silver`)
      .then((response) => {
        const silverPriceHistory = response.data.data.reverse()
        const silverPricecurrent = silverPriceHistory[0].silverprice
        this.setState({
          silverPricecurrent,
        })
        console.log('gold current price', silverPricecurrent)
      })
      .catch(function (error) {
        // handle error
        console.log(error)
      })
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/document/all-doc`)
      .then((res) => {
        const data = res.data.data.reverse()
        const document = data
        const goldDoc = document.filter(
          (document) => document.category === 'Gold'
        )
        const goldDocument = goldDoc[0]
        const silverDoc = document.filter(
          (document) => document.category === 'Silver'
        )
        const silverDocument = silverDoc[0]
        const diamondDoc = document.filter(
          (document) => document.category === 'Diamond'
        )
        const diamondDocument = diamondDoc[0]
        const otherDoc = document.filter(
          (document) => document.category === 'Other'
        )
        const otherDocument = otherDoc[0]
        this.setState({
          goldDocument: goldDocument,
          silverDocument: silverDocument,
          diamondDocument: diamondDocument,
          otherDocument: otherDocument,
        })
      })
      .catch(function (error) {
        // handle error
        console.log(error)
      })
    // API for making charges for gold
  }

  render() {
    const { value, value1, value3 } = this.state

    return (
      <>
        <div>
          <Button
            style={{ width: '10%', marginBottom: '2%' }}
            size="large"
            key="3"
            type="primary"
            onClick={this.showModal1}
          >
            Upload
          </Button>
          <Button
            style={{ marginLeft: '1%' }}
            className="product-button"
            size="large"
            key="3"
            type="default"
            onClick={() => window.location.reload(false)}
          >
            Refresh
          </Button>
          <Button
            style={{ float: 'right' }}
            size="large"
            key="3"
            type="default"
            onClick={this.showMoadal_product_doc_details}
          >
            Product Document
          </Button>
          <Button
            style={{ float: 'right', marginRight: '1%' }}
            size="large"
            key="3"
            type="default"
            onClick={this.handleWebShopStatus}
          >
            {this.state.webShopClose ? 'Enable Web Shop' : 'Disable Web Shop'}
          </Button>
        </div>
        <Modal
          title="Product Document"
          visible={this.state.visible_product_doc_details}
          onCancel={this.handleCancel_product_doc_details}
          style={{ width: 1200 }}
          footer={null}
        >
          <Button
            style={{ float: 'right' }}
            size="large"
            key="3"
            type="default"
            onClick={this.showMoadal_product_doc}
          >
            Document Gallery
          </Button>
          <Divider orientation="left"> Gold </Divider>
          {this.state.goldDocument.docURL1 ? (
            <img
              style={{ width: 200 }}
              src={this.state.goldDocument.docURL1}
              alt={`${this.state.goldDocument.category}-Document`}
            />
          ) : null}
          {this.state.goldDocument.docURL2 ? (
            <img
              style={{ width: 200 }}
              src={this.state.goldDocument.docURL2}
              alt={`${this.state.goldDocument.category}-Document`}
            />
          ) : null}
          {this.state.goldDocument.docURL3 ? (
            <img
              style={{ width: 200 }}
              src={this.state.goldDocument.docURL3}
              alt={`${this.state.goldDocument.category}-Document`}
            />
          ) : null}
          {this.state.goldDocument.docURL4 ? (
            <img
              style={{ width: 200 }}
              src={this.state.goldDocument.docURL4}
              alt={`${this.state.goldDocument.category}-Document`}
            />
          ) : null}
          {this.state.goldDocument.docURL5 ? (
            <img
              style={{ width: 200 }}
              src={this.state.goldDocument.docURL5}
              alt={`${this.state.goldDocument.category}-Document`}
            />
          ) : null}
          <Divider orientation="left"> Silver </Divider>
          {this.state.silverDocument.docURL1 ? (
            <img
              style={{ width: 200 }}
              src={this.state.silverDocument.docURL1}
              alt={`${this.state.silverDocument.category}-Document`}
            />
          ) : null}
          {this.state.silverDocument.docURL2 ? (
            <img
              style={{ width: 200 }}
              src={this.state.silverDocument.docURL2}
              alt={`${this.state.silverDocument.category}-Document`}
            />
          ) : null}
          {this.state.silverDocument.docURL3 ? (
            <img
              style={{ width: 200 }}
              src={this.state.silverDocument.docURL3}
              alt={`${this.state.silverDocument.category}-Document`}
            />
          ) : null}
          {this.state.silverDocument.docURL4 ? (
            <img
              style={{ width: 200 }}
              src={this.state.silverDocument.docURL4}
              alt={`${this.state.silverDocument.category}-Document`}
            />
          ) : null}
          {this.state.silverDocument.docURL5 ? (
            <img
              style={{ width: 200 }}
              src={this.state.silverDocument.docURL5}
              alt={`${this.state.silverDocument.category}-Document`}
            />
          ) : null}
          <Divider orientation="left"> Diamond </Divider>
          {this.state.diamondDocument.docURL1 ? (
            <img
              style={{ width: 200 }}
              src={this.state.diamondDocument.docURL1}
              alt={`${this.state.diamondDocument.category}-Document`}
            />
          ) : null}
          {this.state.diamondDocument.docURL2 ? (
            <img
              style={{ width: 200 }}
              src={this.state.diamondDocument.docURL2}
              alt={`${this.state.diamondDocument.category}-Document`}
            />
          ) : null}
          {this.state.diamondDocument.docURL3 ? (
            <img
              style={{ width: 200 }}
              src={this.state.diamondDocument.docURL3}
              alt={`${this.state.diamondDocument.category}-Document`}
            />
          ) : null}
          {this.state.diamondDocument.docURL4 ? (
            <img
              style={{ width: 200 }}
              src={this.state.diamondDocument.docURL4}
              alt={`${this.state.diamondDocument.category}-Document`}
            />
          ) : null}
          {this.state.diamondDocument.docURL5 ? (
            <img
              style={{ width: 200 }}
              src={this.state.diamondDocument.docURL5}
              alt={`${this.state.diamondDocument.category}-Document`}
            />
          ) : null}
          <Divider orientation="left"> Other </Divider>
          {this.state.otherDocument.docURL1 ? (
            <img
              style={{ width: 200 }}
              src={this.state.otherDocument.docURL1}
              alt={`${this.state.otherDocument.category}-Document`}
            />
          ) : null}
          {this.state.otherDocument.docURL2 ? (
            <img
              style={{ width: 200 }}
              src={this.state.otherDocument.docURL2}
              alt={`${this.state.otherDocument.category}-Document`}
            />
          ) : null}
          {this.state.otherDocument.docURL3 ? (
            <img
              style={{ width: 200 }}
              src={this.state.otherDocument.docURL3}
              alt={`${this.state.otherDocument.category}-Document`}
            />
          ) : null}
          {this.state.otherDocument.docURL4 ? (
            <img
              style={{ width: 200 }}
              src={this.state.otherDocument.docURL4}
              alt={`${this.state.otherDocument.category}-Document`}
            />
          ) : null}
          {this.state.otherDocument.docURL5 ? (
            <img
              style={{ width: 200 }}
              src={this.state.otherDocument.docURL5}
              alt={`${this.state.otherDocument.category}-Document`}
            />
          ) : null}
        </Modal>
        <Modal
          title="Product Document Gallery"
          visible={this.state.visible_product_doc}
          onCancel={this.handleCancel_product_doc}
          style={{ width: 1200 }}
          onOk={this.uploadProductDoc}
        >
          <Radio.Group
            options={documentCategory}
            onChange={(e) => this.setState({ productcategory: e.target.value })}
            value={this.state.productcategory}
            optionType="solid"
          />
          <input
            style={{ margin: '10px' }}
            type="file"
            name="img"
            id="img"
            onChange={(e) => this.onChangeFile1(e)}
          />
          <Button
            size="medium"
            key="3"
            type="primary"
            onClick={this.uploadProductDocImg}
          >
            Add Image
          </Button>
          {this.state.imagelink1 != '' ? (
            <>
              <Divider orientation="right">Selected Document</Divider>
              <img style={{ width: 200 }} src={this.state.imagelink1} />
              <img style={{ width: 200 }} src={this.state.imagelink2} />
              <img style={{ width: 200 }} src={this.state.imagelink3} />
              <img style={{ width: 200 }} src={this.state.imagelink4} />
              <img style={{ width: 200 }} src={this.state.imagelink5} />
            </>
          ) : null}

          <Divider />
          <Row gutter={16}>
            {this.state.productDocImageGallery.map((image) => (
              <Col
                className="gutter-row text-center gallery-image-card"
                span={12}
              >
                <img style={{ width: 180 }} src={image.imageURL} />
                <Button
                  type="primary"
                  className="gallery-button-delete"
                  danger
                  onClick={() => this.deleteDocImage(image._id)}
                >
                  Delete
                </Button>
                <Button
                  type="primary"
                  className="gallery-button-select"
                  onClick={() => this.passDocImageUrl(image.imageURL)}
                >
                  Select
                </Button>
              </Col>
            ))}
          </Row>
        </Modal>
        <Modal
          width="520"
          title="Gallery"
          visible={this.state.visible_gallery}
          footer={null}
          onCancel={this.handleCancel_gallery}
        >
          <input
            style={{ margin: '10px' }}
            type="file"
            name="img"
            id="img"
            onChange={(e) => this.onChangeFile1(e)}
            style={{ width: 1200 }}
          />
          <Button
            size="medium"
            key="3"
            type="primary"
            onClick={this.uploadProductImage}
          >
            Add Image
          </Button>
          <Divider />
          <Row gutter={16}>
            {this.state.productImageGallery.map((image) => (
              <Col
                className="gutter-row text-center gallery-image-card"
                span={6}
              >
                <img style={{ width: 280 }} src={image.imageURL} />
                <Button
                  type="primary"
                  className="gallery-button-delete"
                  danger
                  onClick={() => this.deleteImage(image._id)}
                >
                  Delete
                </Button>
                <Button
                  type="primary"
                  className="gallery-button-select"
                  onClick={() => this.passImageUrl(image.imageURL)}
                >
                  Select
                </Button>
              </Col>
            ))}
          </Row>
        </Modal>
        <Modal
          title="Delete Product"
          okText={'Delete'}
          centered
          visible={this.state.visible_delete}
          onOk={() => this.handleOkdelete(this.state.modal_id)}
          onCancel={this.handleCanceldelete}
        >
          <h1>
            Are you sure you want to delete this Product
            {this.state.modal_title} ?
          </h1>
        </Modal>
        <Modal
          title="Upload Product"
          okText={'Upload'}
          centered
          visible={this.state.visible_upload}
          onOk={this.uploadFormDetails}
          onCancel={this.handleCancel1}
        >
          <Form
            name="Basic"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              name="title"
              label="Title"
              valuePropName="value"
              rules={[{ required: true }]}
            >
              <Input
                className="input-layout"
                value={this.state.title}
                onChange={(e) => this.setState({ title: e.target.value })}
              />
            </Form.Item>

            <Form.Item
              name="subtitle"
              label="Subtitle"
              valuePropName="value"
              rules={[{ required: true }]}
            >
              <Input
                className="input-layout"
                value={this.state.subtitle}
                onChange={(e) => this.setState({ subtitle: e.target.value })}
              />
            </Form.Item>

            <Form.Item
              name="detail"
              label="Detail"
              rules={[{ required: true }]}
            >
              {/* <Input
                className="input-layout"
                value={this.state.detail}
                onChange={(e) => this.setState({ detail: e.target.value })}
              /> */}
              <CKEditor
                editor={ClassicEditor}
                data={this.state.ckdata}
                onInit={(editor) => {
                  // You can store the "editor" and use when it is needed.
                  console.log('Editor is ready to use!', editor)
                }}
                onChange={(event, editor) => {
                  const data = editor.getData()
                  console.log({ event, editor, data })
                  this.setState({ detail: data })
                }}
                onBlur={(event, editor) => {
                  console.log('Blur.', editor)
                }}
                onFocus={(event, editor) => {
                  console.log('Focus.', editor)
                }}
              />
            </Form.Item>

            <Form.Item
              name="category"
              label="Category"
              rules={[{ required: true }]}
            >
              <Radio.Group
                className="input-layout"
                options={options}
                onChange={(e) => this.setState({ category: e.target.value })}
                value={this.state.category}
                optionType="solid"
              />
            </Form.Item>

            {this.state.category === 'Diamond' ? (
              <>
                <Form.Item
                  name="Price"
                  label="Price"
                  rules={[{ required: true }]}
                >
                  <Input
                    className="input-layout"
                    value={this.state.price}
                    onChange={(e) => this.setState({ price: e.target.value })}
                  />
                </Form.Item>
              </>
            ) : null}
            {this.state.category === 'Mints' ? (
              <>
                <Form.Item
                  name="Price"
                  label="Price"
                  rules={[{ required: true }]}
                >
                  <Input
                    className="input-layout"
                    value={this.state.price}
                    onChange={(e) => this.setState({ price: e.target.value })}
                  />
                </Form.Item>
              </>
            ) : null}
            {this.state.category === 'others' ? (
              <>
                <Form.Item
                  name="Price"
                  label="Price"
                  rules={[{ required: true }]}
                >
                  <Input
                    className="input-layout"
                    value={this.state.price}
                    onChange={(e) => this.setState({ price: e.target.value })}
                  />
                </Form.Item>
              </>
            ) : null}

            <Form.Item
              name="purity"
              label={categoryToPurity(this.state.category)}
              rules={[{ required: true }]}
            >
              <Input
                className="input-layout"
                addonAfter={this.state.category === 'Diamond' ? 'kt' : '%'}
                value={this.state.purity}
                onChange={(e) => this.setState({ purity: e.target.value })}
              />
            </Form.Item>

            <Form.Item
              name="weight"
              label="Weight"
              rules={[{ required: true }]}
            >
              <Radio.Group
                className="input-layout"
                options={weight}
                onChange={(e) => this.setState({ weight: e.target.value })}
                value={this.state.weight}
                optionType="button"
                buttonStyle="solid"
              />
            </Form.Item>

            <Form.Item
              name="dimension"
              label="Dimensions"
              valuePropName="value"
              rules={[{ required: true }]}
            >
              <Input
                className="input-layout"
                value={this.state.dimension}
                onChange={(e) => this.setState({ dimension: e.target.value })}
              />
            </Form.Item>

            <Form.Item
              name="quantity"
              label="Quantity"
              rules={[{ required: true }]}
            >
              <Input
                className="input-layout"
                type={'number'}
                value={this.state.quantity}
                onChange={(e) => this.setState({ quantity: e.target.value })}
              />
            </Form.Item>

            <Form.Item
              name="upload"
              label="Image"
              rules={[{ required: true }]}
              valuePropName="fileList"
            >
              <Button className="input-layout" onClick={this.showModal_gallery}>
                Image
              </Button>
              <div className="upload-btn-wrapper">
                {this.state.imagelink1 == '' ? null : <h3>Selected Image </h3>}
                {this.state.imagelink1 === '' ? null : (
                  <img
                    style={{ width: 150 }}
                    src={this.state.imagelink1}
                    alt="selected image"
                  />
                )}
                {this.state.imagelink2 === '' ? null : (
                  <img
                    style={{ width: 150 }}
                    src={this.state.imagelink2}
                    alt="selected image"
                  />
                )}
                {this.state.imagelink3 === '' ? null : (
                  <img
                    style={{ width: 150 }}
                    src={this.state.imagelink3}
                    alt="selected image"
                  />
                )}
                {this.state.imagelink4 === '' ? null : (
                  <img
                    style={{ width: 150 }}
                    src={this.state.imagelink4}
                    alt="selected image"
                  />
                )}
                {this.state.imagelink5 === '' ? null : (
                  <img
                    style={{ width: 150 }}
                    src={this.state.imagelink5}
                    alt="selected image"
                  />
                )}
              </div>
            </Form.Item>
          </Form>
        </Modal>

        <div>
          <h3>Recent Products</h3>
        </div>
        {this.state.loading ? this.renderSkeleton() : this.renderProduct()}
        <Modal
          title="Product Details"
          centered
          visible={this.state.visible_product}
          onCancel={this.handleCancel2}
          className="product-modal"
          footer={[null]}
        >
          <Row>
            <Col span={12}>
              <div>
                <img
                  className="card-product-img"
                  src="https://5.imimg.com/data5/IC/TP/RI/SELLER-88265349/24k-gold-bars-for-sale-500x500.jpg"
                  alt="trending product"
                />
              </div>
            </Col>
            <Col span={12} style={{ paddingLeft: '50px' }}>
              <Row>
                <Col span={20}>
                  <div>
                    <h2 className="product-modal-title">
                      {this.state.modal_title}
                    </h2>
                  </div>
                  <div>
                    <h2 className="product-modal-subtitle">
                      {this.state.modal_subtitle}
                    </h2>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col span={12}>
                  <div className="product-modal-price">
                    <h2>Dimensions</h2>
                    <p>{/* {product.dimension} */}</p>
                    <h2>Price</h2>
                    <p></p>
                  </div>
                </Col>
              </Row>
              <Row className="margin-btm">
                <Col span={12}>
                  <div className="margin-btm">
                    <h3>Material</h3>
                  </div>
                </Col>
                <Col span={12}>
                  <div className="margin-btm">
                    <h3>Weight</h3>
                    <Tag color="geekblue">{this.state.modal_weight}g</Tag>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <div>
                    <h3>Created At</h3>
                    <p>{this.state.modal_createdAt}</p>
                  </div>
                </Col>
                <Col span={12}>
                  <div>
                    <h3>Updated At</h3>
                    <p>{this.state.modal_updatedAt}</p>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Modal>
      </>
    )
  }
}

export default Product
